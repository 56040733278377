import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, {
  SectionObject,
  EmailInputWrapper,
  NameInputWrapper,
  WebsiteInputWrapper,
  InputWrapperDefault,
  TextareaInputWrapper,
} from './contact.style';
import Input from 'common/src/components/Input';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import ImageOne from 'common/src/assets/image/saas/scheduleImg.jpg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextHr from 'common/src/components/textLine';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateWebsite(website) {
  const re = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  return re.test(String(website).toLowerCase());
}

const requiredFields = [
  { key: 'message', label: 'Message' },
  { key: 'url', label: 'Website' },
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email address' },
];

const initialState = {
  email: null,
  name: null,
  url: null,
  topic: null,
  message: null,
};

const ContactSection = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  sectionSubTitle,
  outlinedBtnStyle,
  handleOpenDemoModal,
}) => {
  const [acceptedConditions, setAcceptedConditions] = useState(false);

  const [form, setForm] = useState(initialState);

  const [notification, setNotification] = useState({
    open: false,
    text: null,
    sort: null,
  });
  const [backdrop, setBackdrop] = useState(false);

  function handleValidation() {
    var validation = true;
    var validationMessage = '';
    requiredFields.forEach(vali => {
      if (!form[vali.key]) {
        validation = false;
        validationMessage += validationMessage ? `, ${vali.label}` : vali.label;
      }
    });
    if (!validateEmail(form.email)) {
      validation = false;
      validationMessage += validationMessage
        ? ', a valid email address'
        : 'a valid email address';
    }
    if (form.website && !validateWebsite(form.website)) {
      validation = false;
      validationMessage += validationMessage
        ? ', a valid website url'
        : 'a valid website url';
    }

    return { validation, validationMessage };
  }

  function handleSubmit() {
    setBackdrop(true);
    if (!acceptedConditions) {
      setNotification({
        open: true,
        sort: 'error',
        text: `Please accept terms and conditions`,
      });
      setBackdrop(false);
      return;
    }
    const { validation, validationMessage } = handleValidation();
    if (!validation) {
      setNotification({
        open: true,
        sort: 'error',
        text: `Please enter ${validationMessage}`,
      });
      setBackdrop(false);

      return;
    } else {
      axios
        .post('https://api-dot-salesoceanio.ew.r.appspot.com/api/submitForm', {
          ...form,
          soURL: window.location.href,
          sendVerificationEmail: true,
        })
        .then(res => {
          if (res.status === 200) {
            setForm({
              email: null,
              name: null,
              url: null,
              topic: null,
              message: null,
            });
            var text =
              'Successfully submitted, we will get in touch with you soon!';

            setNotification({
              open: true,
              sort: 'success',
              text: text,
            });
            setBackdrop(false);
          } else {
            setNotification({
              open: true,
              sort: 'success',
              text:
                'Oops something went wrong while sending the message, please try again or contact us at: info@salesocean.io',
            });
            setBackdrop(false);
          }
        })
        .catch(err => {
          setNotification({
            open: true,
            sort: 'success',
            text:
              'Oops something went wrong while sending the message, please try again or contact us at: info@salesocean.io',
          });
          setBackdrop(false);
        });
      return;
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification({
      open: false,
      text: '',
      sort: '',
    });
  };

  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash.includes('demo')) {
        setForm({ ...form, topic: 'Demo request' });
      }
      if (hash.includes('beta')) {
        setForm({ ...form, topic: 'Request for beta access' });
      }
    }
  }, []);

  return (
    <VisitorSectionWrapper id="beyondABM">
      <SectionObject>
        <Fade left>
          <Image
            src={ImageOne}
            alt="VisitorDashboard1"
            style={{ maxWidth: '70%', margin: 'auto' }}
          />
        </Fade>
      </SectionObject>
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={
              <>
                <TextHr />
                <Text content="GET IN TOUCH!" {...sectionSubTitle} />
              </>
            }
            description={
              <>
                <NameInputWrapper>
                  <Input
                    value="string"
                    onChange={newValue => setForm({ ...form, name: newValue })}
                    placeholder="Name"
                    iconPosition="left"
                    aria-label="name"
                  />
                </NameInputWrapper>
                <EmailInputWrapper>
                  <Input
                    value="string"
                    onChange={newValue => setForm({ ...form, email: newValue })}
                    inputType="email"
                    placeholder="Email Address"
                    iconPosition="left"
                    aria-label="email"
                  />
                </EmailInputWrapper>
                <WebsiteInputWrapper>
                  <Input
                    value="string"
                    onChange={newValue => setForm({ ...form, url: newValue })}
                    placeholder="Company website"
                    iconPosition="left"
                    aria-label="website"
                  />
                </WebsiteInputWrapper>
                <InputWrapperDefault>
                  <Input
                    value="string"
                    onChange={newValue => setForm({ ...form, topic: newValue })}
                    placeholder="Topic"
                    aria-label="topic"
                    defaultValue={form.topic}
                  />
                </InputWrapperDefault>
                <TextareaInputWrapper>
                  <Input
                    value="string"
                    inputType="textarea"
                    onChange={newValue =>
                      setForm({ ...form, message: newValue })
                    }
                    placeholder="Message"
                    aria-label="message"
                  />
                </TextareaInputWrapper>
                <FormControlLabel
                  style={{ fontSize: '0.7rem' }}
                  control={
                    <Checkbox
                      checked={acceptedConditions}
                      name="conditions"
                      color="primary"
                      onChange={() => {
                        setAcceptedConditions(!acceptedConditions);
                      }}
                      size="small"
                      required={true}
                    />
                  }
                  label={
                    <span>
                      I agree to{' '}
                      <span
                        onClick={() => window.open('/termsAndConditions')}
                        style={{ textDecoration: 'underline' }}
                      >
                        Terms and Conditions
                      </span>{' '}
                      &amp;{' '}
                      <span
                        onClick={() => window.open('/privacy')}
                        style={{ textDecoration: 'underline' }}
                      >
                        Privacy policy
                      </span>
                      <br />
                      <span style={{ fontSize: '11px' }}>
                        I consent that SalesOcean collects and use my personal
                        data for marketing related activities.
                      </span>
                    </span>
                  }
                />
              </>
            }
            button={
              <>
                <Button title="SUBMIT" {...btnStyle} onClick={handleSubmit} />
                <Button
                  title="OR BOOK A DEMO"
                  {...outlinedBtnStyle}
                  variant="outlined"
                  onClick={handleOpenDemoModal}
                />
              </>
            }
          />
        </Box>
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={notification.sort}>
            {notification.text}
          </Alert>
        </Snackbar>
        <Backdrop
          style={{ zIndex: 99999999, color: '#fff' }}
          open={backdrop}
          onClick={() => setBackdrop(!backdrop)}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      </Container>
    </VisitorSectionWrapper>
  );
};

ContactSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
  outlinedBtnStyle: PropTypes.object,
};

ContactSection.defaultProps = {
  outlinedBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    ml: ['0', '18px'],
    type: 'button',
    colors: 'third',
  },
  textArea: {
    width: ['100%', '100%', '55%'],
    ml: [0, 0, '58%'],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  title: {
    fontSize: ['20px', '20px', '24px', '24px', '28px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '80%', '80%'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '60%', '60%'],
  },
  btnStyle: {
    minWidth: '130px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    mr: ['5px', '15px'],
    mt: '5px',
    colors: 'primaryWithBg',
  },
};

export default ContactSection;
