import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import { ContactInfo, TextRootWrapper } from './aboutUs.style';

const AboutUs = ({
  sectionWrapper,
  row,
  title,
  description,
  textArea,
  btnStyle,
}) => {
  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...row}>
          <Box {...textArea} style={{ width: '100%' }}>
            <Heading
              {...title}
              content="We strive to make your Sales Team more efficient."
            />
            <TextRootWrapper>
              <ContactInfo>
                {/* <Text
                {...description}
                content="General"
                style={{ textDecoration: 'underline' }}
              /> */}
                <Text
                  {...description}
                  content="Sales Software Solutions B.V."
                />
                <Text {...description} content="CoC: 78541816" />
                <Text {...description} content="VAT: NL861442568B01" />
                <Text {...description} content="Mail us: info@salesocean.io" />
              </ContactInfo>
            </TextRootWrapper>
            <TextRootWrapper>
              <ContactInfo>
                {/* <Text
                {...description}
                content="General"
                style={{ textDecoration: 'underline' }}
              /> */}

                <Text {...description} content="Street: Torenallee 20" />
                <Text {...description} content="Zip: 5617 BC" />
                <Text {...description} content="City: Eindhoven" />
                <Text {...description} content="Country: Netherlands" />
              </ContactInfo>
            </TextRootWrapper>
            {/* <ContactInfo>
              <Text
                {...description}
                content="Address"
                style={{ textDecoration: 'underline' }}
              />

              <Text {...description} content="Netherlands" />
              <Text {...description} content="St.Rochusstraat 71" />
              <Text {...description} content="5611RG Eindhoven" />
            </ContactInfo> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

AboutUs.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

AboutUs.defaultProps = {
  sectionWrapper: {
    as: 'section',
    className: 'trial-section',
    pt: ['80px', '80px', '80px', '80px'],
    pb: ['40px', '40px', '40px', '40px'],
    backgroundColor: 'white',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: ['32px', '32px', '36px', '42px', '48px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.025em',
    mb: '1rem',
    textAlign: 'left',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['1.25rem', '1.25rem', '1.25rem', '1.25rem', '1.25rem'],
    color: '#2a313c',
    lineHeight: '2.1',
    textAlign: 'left',
    mb: 0,
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
};

export default AboutUs;
