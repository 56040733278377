import React from 'react';

const style = {
  width: '4rem',
  borderWidth: 0,
  height: '3px',
  borderRadius: '5px',
  color: '#5268db',
  backgroundColor: '#5268db',
  marginLeft: 0,
  marginBottom: '1rem',
  display: 'inline-block',
};
const TextHr = ({ styleProps }) => (
  <>
    <hr
      style={{
        ...style,
        ...styleProps,
      }}
    />
    <hr
      style={{
        ...style,
        ...styleProps,
        marginLeft: '1rem',
        width: '1rem',
      }}
    />
  </>
);

export default TextHr;
