import styled from 'styled-components';

export const ContactInfo = styled.div``;

export const TextRootWrapper = styled.div`
  width: 50%;
  display: inline-block;
  padding: 1rem 2rem;
  justify-self: center;
  margin: auto;
`;
