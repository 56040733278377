import styled from 'styled-components';

export const RootWrapper = styled.div`
  width: 40rem;
  justify-self: center;
  background-color: white;
  border-radius: 5px;
  padding-bottom: 1rem;
  overflow-y: scroll;
  max-height: 35rem;
  @media (max-width: 650px) {
    width: 30rem;
    padding: 1rem;
  }
  @media (max-width: 500px) {
    width: 22rem;
  }
`;
