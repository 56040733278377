import React, { Fragment, useState } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import Footer from '../containers/Saas/Footer';
import ContactSection from '../containers/Saas/contact';
import Layout from '../components/layout';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import AboutUsSection from '../containers/Saas/aboutUsSection';
import CallToActionBottom from '../containers/Saas/TrialSection';
import ModalForm from '../containers/Saas/contactModal';

export default () => {
  const [openDemoModal, setOpenDemoModal] = useState(false);

  function handleOpenDemoModal() {
    setOpenDemoModal(true);
  }

  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title="Contact us"
          description="We are happy to explain more about our sales development platform and explore how we can help you! Make your sales process well-organised again."
        />
        <ResetCSS />
        <GlobalStyle />
        <Layout>
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <ContactSection handleOpenDemoModal={handleOpenDemoModal} />
            <AboutUsSection />
            <CallToActionBottom />
            <Footer />
            <ModalForm open={openDemoModal} setOpen={setOpenDemoModal} />
          </ContentWrapper>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
